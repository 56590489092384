import { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import PropTypes from "prop-types";

import { Loader } from "components";
import InvoiceStatusTable from "./InvoiceStatusTable";
import ReleaseStatusTable from "./ReleaseStatusTable";
import BlockStatusTable from "./BlockStatusTable";

import { useFetchData } from "hooks";

const ZohoOrderLifeCycle = ({ orderNumber }) => {
  const {
    refetch: fetchZohoOrderLifeCycle,
    isFetching,
    data: zohoOrdersList,
  } = useFetchData(
    "fetch-zoho-order-info",
    `/joms/api/external/v1/zoho-order-view?orderNumber=${orderNumber}`,
  );

  useEffect(() => {
    fetchZohoOrderLifeCycle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <Box p={2}>
          <Typography fontSize={14} fontWeight={"bold"}>
            Block status
          </Typography>
          <Box mb={4}>
            <BlockStatusTable
              blockStatusList={zohoOrdersList?.data?.blocksList}
            />
          </Box>

          <Typography fontSize={14} fontWeight={"bold"}>
            Invoice status
          </Typography>
          <Box mb={4}>
            <InvoiceStatusTable
              invoiceStatusList={zohoOrdersList?.data?.invoiceStatusViewList}
            />
          </Box>

          <Typography fontSize={14} fontWeight={"bold"}>
            Release status
          </Typography>
          <Box mb={2}>
            <ReleaseStatusTable
              releaseStatusList={
                zohoOrdersList?.data?.orderBlockReleaseRequestList
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};

ZohoOrderLifeCycle.propTypes = {
  orderNumber: PropTypes.string.isRequired,
};

export default ZohoOrderLifeCycle;
