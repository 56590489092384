import * as Yup from "yup";
import { DELIVERY_TYPES } from "constants";

export const doSchema = Yup.object().shape({
  pickUpSlotFrom: Yup.string().when("deliveryType", {
    is: DELIVERY_TYPES?.jots,
    then: Yup.string().required("Please enter Pick-up slot: From field"),
    otherwise: Yup.string().notRequired(),
  }),
  pickUpSlotTo: Yup.date().when("deliveryType", {
    is: DELIVERY_TYPES?.jots,
    then: Yup.date().required("Please enter Pick-up slot: To field"),
    otherwise: Yup.date().notRequired(),
  }),
});
