import { Box, Typography } from "@mui/material";

import { TooltipLabel } from "components";
import { GridSkeleton } from "CustomStyledComponents";

import HoverBlock from "./HoverBlock";
import FileUpload from "./FileUpload";
import { ColumnView, ViewableItem } from "./CustomDetailComponents";

import { pascalCase, validateNull } from "utils";
import { useCategoryTypeCheck } from "hooks";
import theme from "themeProvider";

const SellerTrack = ({ orderInfo }) => {
  const { isDirect } = useCategoryTypeCheck();

  const getSellerInfo = (sellerDetails) => {
    if (sellerDetails) {
      const { sellerKey } = sellerDetails;
      let sellerInfo = orderInfo?.sellerDetails[sellerKey];
      let address;
      if (sellerInfo?.address) {
        const { streetLine, city, state, postalCode } = sellerInfo?.address;
        address = `${streetLine ? streetLine + ",\n" : ""} ${
          city ? city + ", " : ""
        } ${state ? state + ", " : ""} ${postalCode ?? ""}`;
      }

      let formattedSellerInfo = {
        Name: orderInfo?.sellerDetails[sellerKey]?.name,
        ...(orderInfo?.sellerDetails[sellerKey]?.gstin && {
          GSTIN: orderInfo?.sellerDetails[sellerKey]?.gstin,
        }),
      };
      if (address) return { ...formattedSellerInfo, Address: address };
      return formattedSellerInfo;
    }
  };

  return (
    <>
      <Box mb={8}>
        {orderInfo?.childOrders?.map((ele, ind) => {
          return (
            <Box
              mb={orderInfo?.childOrders.length - 1 !== ind ? 4 : 0}
              key={ele?.sellerOrderNumber}
              display={"flex"}
              flexDirection={"column"}
            >
              <GridSkeleton
                firstChild={
                  <ViewableItem
                    label={"Seller order no."}
                    value={ele?.sellerOrderNumber}
                  />
                }
                secondChild={
                  <ColumnView label={"Seller"}>
                    <TooltipLabel
                      placement="right"
                      title={<HoverBlock data={getSellerInfo(ele)} />}
                      labelChildren={
                        <Typography
                          fontSize={16}
                          fontWeight={"bold"}
                          color={theme.palette.secondary.main}
                          style={{ wordBreak: "break-word" }}
                        >
                          {pascalCase(ele?.sellerName)}
                        </Typography>
                      }
                    />
                  </ColumnView>
                }
                thirdChild={
                  <ViewableItem
                    label={"Seller order status"}
                    value={ele?.currentState}
                  />
                }
              />

              {!!ele?.files &&
                !!Object.keys(ele.files).length &&
                Object.keys(ele.files).map((type) => (
                  <Box width={"80%"} mt={2}>
                    <FileUpload
                      fileDetails={ele?.files?.[type]}
                      type={type}
                      orderNumber={ele?.sellerOrderNumber}
                    />
                  </Box>
                ))}
            </Box>
          );
        })}
      </Box>
      <>
        <GridSkeleton
          firstChild={
            <ViewableItem
              label={"JODL order no."}
              value={validateNull(orderInfo?.jodlOrderNumber)}
            />
          }
          secondChild={
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "baseline",
              }}
            >
              <Typography>{"JODL order status"}</Typography>
              <Typography style={{ fontWeight: "bold", marginTop: 6 }}>
                {validateNull(orderInfo?.jodlOrderStatus)}
              </Typography>
              <Typography variant="body2">
                {orderInfo?.jodlFailedReason}
              </Typography>
            </Box>
          }
          thirdChild={<></>}
        />
      </>
    </>
  );
};

export default SellerTrack;
